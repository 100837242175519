import React, { lazy, Suspense }from 'react';
import './css/App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFutbol, faBars, faHome, faSignOut } from '@fortawesome/pro-light-svg-icons';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import Loading from '../Loading/Loading';
import Login from '../Login/Login';
import useToken from '../../utils/utils';

const Dashboard = lazy(() => import('../Dashboard/Dashboard'));
const Predictions = lazy(() => import('../Predictions/Predictions'));
const Leagues = lazy(() => import('../Leagues/Leagues'));
const Admin = lazy(() => import('../Admin/Admin'));
const Fixtures = lazy(() => import('../Fixtures/Fixtures'));
const Results = lazy(() => import('../Results/Results'));

function App() {
  const { token, setToken } = useToken();
  
  const LogUserOut = (() => setToken(false));
  
  if(!token) {
    return <Login token={token} setToken={setToken} />
  }
  
  return (
    <div className="grid-container full main-content">
      
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
          
            <Suspense fallback={<Loading />}>
              {<Dashboard
                token={token}
                 />}
            </Suspense>
            
          </Route>
          <Route path="/leagues">
        
              <Suspense fallback={<Loading />}>
                {<Leagues
                  token={token}
                   />}
              </Suspense>
              
          </Route>
          <Route exact path="/predictions">
        
            <Suspense fallback={<Loading />}>
              {<Predictions
                token={token}
                 />}
            </Suspense>
            
          </Route>
          <Route path="/predictions/:regID([0-9]*)">
          
            <Suspense fallback={<Loading />}>
              {<Predictions
                token={token}
                 />}
            </Suspense>
            
          </Route>
          
          <Route exact path="/admin">
      
            <Suspense fallback={<Loading />}>
              {<Admin
                token={token}
                 />}
            </Suspense>
            
          </Route>
        
          <Route exact path="/admin/fixtures">
        
            <Suspense fallback={<Loading />}>
              {<Fixtures
                token={token}
                 />}
            </Suspense>
            
          </Route>
          
          <Route exact path="/admin/results">
      
          <Suspense fallback={<Loading />}>
            {<Results
              token={token}
               />}
          </Suspense>
          
        </Route>
          
        </Switch>
      
      <div className="grid-x grid-padding-x align-middle nav-bar">
        <div className="small-3 cell text-center">
          <Link to="/"><FontAwesomeIcon size='2x' icon={ faHome } /> <br /><span>Home</span></Link>
        </div>
        <div className="small-3 cell text-center">
          <Link to="/leagues"><FontAwesomeIcon size='2x' icon={ faBars } /> <br /><span>League</span></Link>
        </div>
        <div className="small-3 cell text-center">
          <Link to="/predictions"><FontAwesomeIcon size='2x' icon={ faFutbol } /> <br /><span>Predictions</span></Link>
        </div>
        <div className="small-3 cell text-center">
          <button onClick={LogUserOut}><FontAwesomeIcon size='2x' icon={ faSignOut } /> <br /><span>Logout</span></button>
        </div>
      </div>
      
      </BrowserRouter>
      
    </div>
  );
}

export default App;
