import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faTools } from '@fortawesome/pro-light-svg-icons';

import logo from "./images/appleicon.png";

function Header({ token, refresh }) {
  
  const handleRefresh = () => {
    window.location.reload();
  };
  
  const handleAdmin = () => {
    window.location.href = "/admin";
  };
  
  return (
      
      <div className="grid-x grid-padding-x header-bar">
        <div className="small-3 cell text-left">
        {(token === 57) &&
          <button
            className="button sign-out-button"
            onClick={handleAdmin}
            ><FontAwesomeIcon 
            icon={ faTools } />
          </button>
        }
        </div>
      
        <div className="small-6 cell text-center">
          <img src={logo} alt="Predict 6 Logo" />
        </div>
        <div className="small-3 cell text-right">
        {(token && refresh)  && 
          <button
          className="button sign-out-button"
          onClick={handleRefresh}
          ><FontAwesomeIcon 
          icon={ faSync } /></button>}
        </div>
      </div>
      
  );
}

export default Header;
