import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';

function Loading() {
	
	return (
		<div className="small-12 cell">
			<div className="grid-x grid-padding-x align-middle loading">
			  <div className="small-12 cell text-center">
				<FontAwesomeIcon size='2x' icon={ faSpinnerThird } spin />
			  </div>
			</div>
		</div>
	);
}
	
export default Loading;