import React from 'react';
import './css/Button.css';

function Button({ children, className, tag, target, url, value }) {
	
	const tag_type = tag;
	
	if (tag_type === "a") {
		return (
			<a 
			href={url} 
			className={`button ${className}`} 
			target={target}
			>{children}</a>
		);
	}
	else if (tag_type === "input") {
		return (
			<input 
			type="submit" 
			className={`button ${className}`} 
			value={value} 
			/>
		)
	}
	else if (tag_type === "button") {
		return (
			<button 
			type="button" 
			className={`button ${className}`} 
			value={value}
			>{children}</button>
		)
	}
	else {
		return null
	}
}
	
export default Button;