import React, { useState } from 'react';
import validator from 'validator'
import './css/Login.css';

import Button from '../Button/Button';
import Header from "../Header/Header";

async function loginUser(credentials) {
   return fetch('/api/app-login.php', {
     method: 'POST',
     headers: {
       'Content-Type': 'application/json'
     },
     body: JSON.stringify(credentials)
   })
   .then(function (response) {
      return response.json();
  }).then(function (json) {
      return json;
  }).catch(function (errors) {
      return "error";
  });
}
  
function Login({ token, setToken }) {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [error_report, setErrorReport] = useState(false);
  
  const handleSubmit = async e => {
    e.preventDefault();
    setSubmitting(true);
    setErrorReport(false);
    
    if (username === "" && password === "") {
      setSubmitting(false);
      setErrorReport("<b>ERROR!</b> You must enter both an email address and password");
    }
    else if (username !== "" && !validator.isEmail(username)) {
      setSubmitting(false);
      setErrorReport("<b>ERROR!</b> Email address is invalid");
    }
    else if (username === "") {
      setSubmitting(false);
      setErrorReport("<b>ERROR!</b> You must enter an email address");
    }
    else if (password === "") {
      setSubmitting(false);
      setErrorReport("<b>ERROR!</b> You must enter a password");
    }
    else {
    
      const token = await loginUser({
        loginClass: "APIEnter",
        regEmail: username,
        regPassword: password
      });
      setSubmitting(false);
      if (token === "error") {
        setErrorReport("<b>ERROR!</b> Please check your email and password and try again");
      }
      else if (token?.data.errorExists) {
        setErrorReport(token?.data.errorText);
      }
      else {
        setToken(JSON.parse(token.data));
      }
    }
  }
  
  return (
    <div className="grid-container fluid login">
    
      <Header 
      token={token}
      refresh={false} />
      
      <div className="grid-x grid-padding-x body-wrapper">
        <div className="small-12 cell">
        
        {submitting &&
          <div className="grid-x grid-padding-x">
            <div className="small-12 large-4 large-offset-4 cell">
              <div className="callout warning">
                Verifying your credentials......
              </div>
            </div>
          </div>
        }
        
        {error_report &&
          <div className="grid-x grid-padding-x">
            <div className="small-12 large-4 large-offset-4 cell">
              <div className="callout alert" dangerouslySetInnerHTML={{__html: error_report}}>
              </div>
            </div>
          </div>
        }
          <div className="grid-x grid-padding-x">
            <div className="small-12 cell">
              <form onSubmit={handleSubmit} >
                <div className="grid-x grid-padding-x">
                  <div className="small-12 large-4 large-offset-4 cell">
                    <input type="text" placeholder="Email Address" onChange={e => setUserName(e.target.value)} />
                  </div>
                  <div className="small-12 large-4 large-offset-4 cell">
                    <input type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                  </div>
                </div>
                
                <div className="grid-x grid-padding-x">
                  <div className="small-12 large-4 large-offset-4 cell text-center">
                    <button 
                    type="submit" 
                    className="button expanded"
                    disabled={submitting}
                    >Login</button>
                  </div>
                </div>
              </form>
            </div>
        
            <div className="small-12 cell">
              
              <div className="grid-x grid-padding-x">
                <div className="small-12 large-4 large-offset-4 cell text-center">
                  <Button
                    className="secondary expanded" 
                    tag="a"
                    target="_blank"
                    url="https://predict6.com/forgotten-password.php"
                  >Forgotten Password
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
